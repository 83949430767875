import { gql } from '@apollo/client';

const userData = gql`
  fragment userData on User {
    id
    address
    birthday
    card {
      last4
    }
    city
    email
    status
    firstname
    gender
    id
    lastname
    privacy {
      newsletter
      notifications
      publicProfile
    }
    sessionToken
    subscription {
      id
      duration
      renew
      price
      currency
      period
      periodUnit
    }
    workplace
    zipCode
    country
  }
`;

export const SIGN_IN = gql`
  mutation signIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      ...userData
    }
  }
  ${userData}
`;

export const AUTH = gql`
  query auth($token: String!) {
    auth(token: $token) {
      ...userData
    }
  }
  ${userData}
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password) {
      ...userData
    }
  }
  ${userData}
`;

export const SEND_ACTIVATION_LINK = gql`
  mutation sendActivationLink($email: String!) {
    sendActivationLink(email: $email)
  }
`;

export const ACTIVATE_ACCOUNT = gql`
  query userWithActivationCode($code: String!) {
    userWithActivationCode(code: $code) {
      ...userData
    }
  }
  ${userData}
`;

export const SIGN_UP = gql`
  mutation signUp(
    $email: String!
    $password: String!
    $firstname: String
    $lastname: String
    $address: String
    $zipCode: String
    $city: String
    $premium: Boolean
    $termsAccepted: Boolean
    $voucher: String
    $productId: Int
  ) {
    signUp(
      email: $email
      password: $password
      firstname: $firstname
      lastname: $lastname
      address: $address
      zipCode: $zipCode
      city: $city
      premium: $premium
      termsAccepted: $termsAccepted
      voucher: $voucher
      productId: $productId
    ) {
      ...userData
    }
  }
  ${userData}
`;

export const INIT_APP = gql`
  query initApp {
    session {
      ...userData
    }
  }
  ${userData}
`;

export const GET_PRODUCTS = gql`
  query getPrice {
    app {
      id
      product {
        currency
        period
        price
        productId
      }
      products {
        currency
        period
        price
        productId
      }
    }
  }
`;

export const CREATE_CARD_SECRET = gql`
  mutation cardSecret {
    cardSecret
  }
`;

export const GET_USER = gql`
  query getUser($id: ID!) {
    user(id: $id) {
      ...userData
    }
  }
  ${userData}
`;

export const GET_USER_ID = gql`
  query getCurrentUser {
    currentUserId
  }
`;
