import React, { useMemo, Suspense } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import Spinner from 'components/Spinner';
import ErrorBoundary from '../SuspenseErrorBoundary';

const PrivatableRoute = ({ component: Component, auth }) => {
  const { pathname } = useLocation();
  const navState = useMemo(() => ({ pathBack: pathname }), [pathname]);

  return auth ? (
    <ErrorBoundary>
      <Suspense fallback={<Spinner />}>
        <Component />
      </Suspense>
    </ErrorBoundary>
  ) : (
    <Navigate to="/anmelden" state={navState} />
  );
};
PrivatableRoute.defaultProps = {
  auth: false,
};

PrivatableRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  auth: PropTypes.bool,
};

export default PrivatableRoute;
