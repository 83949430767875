import styled from 'styled-components';

import { fontStyles } from 'theme';

export const Wrapper = styled.div`
  height: 33px;
  width: ${({ isDropdown }) => (isDropdown ? '100%' : 'fit-content')};
  padding: 0 12px;
  border: 1px solid var(--color-primary);
  border-width: ${({ isDropdown }) => (isDropdown ? '1px 0 0 0' : '1px')};
  border-color: ${({ isDropdown }) => (isDropdown ? 'var(--color-primary)' : 'var(--color-black)')};
  border-radius: ${({ isDropdown }) => (isDropdown ? 0 : 'var(--border-radius)')};
  padding: 0 12px;
  @media (hover) {
    &:hover {
      border-color: var(--color-primary);
      * {
        color: var(--color-primary);
      }
    }
  }
`;

export const Button = styled.button`
  ${fontStyles.regularSmall}
  width: ${({ isDropdown }) => (isDropdown ? '100%' : 'fit-content')};
  height: 100%;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  &:active {
    color: var(--color-primary);
  }
`;
