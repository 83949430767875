import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Container, Title, Text, Button } from './styles';

const ErrorPage = ({ isSuspense }) => {
  const handleReload = useCallback(() => window.location.reload(), []);

  return (
    <Container>
      <Title>Oops :(</Title>
      <Text>
        {isSuspense
          ? 'Wir konnten keine Verbindung zum Server herstellen. Bitte überprüfen Sie Ihre Internetverbindung.'
          : 'Auf Ihrem Computer ist ein Problem aufgetreten. Wir haben die Fehlerinformation gesammelt und anonymisiert an unsere Fehleranalyse-Software übertragen. Wir versuchen, das Problem so schnell wie möglich zu beheben.'}
      </Text>
      <Button type="button" onClick={handleReload}>
        Seite neu laden
      </Button>
    </Container>
  );
};

ErrorPage.defaultProps = { isSuspense: false };
ErrorPage.propTypes = { isSuspense: PropTypes.bool };

export default ErrorPage;
