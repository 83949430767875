import React from 'react';
import PropTypes from 'prop-types';

import { Container, LeftLine, RightLine } from './styles';

const ArrowIcon = ({ isOpen, color, className }) => (
  <Container className={className}>
    <LeftLine $isOpen={isOpen} $color={color} />
    <RightLine $isOpen={isOpen} $color={color} />
  </Container>
);

ArrowIcon.defaultProps = {
  isOpen: false,
  color: 'var(--color-black)',
  className: null,
};

ArrowIcon.propTypes = {
  isOpen: PropTypes.bool,
  color: PropTypes.string,
  className: PropTypes.string,
};

export default ArrowIcon;
