import styled from 'styled-components';

import { fontStyles } from 'theme';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  column-gap: 20px;
  width: 100%;
  border-top: 1px solid var(--color-grey-94);
  padding: 15px 60px 30px 30px;
  @media (max-width: 1023px) {
    column-gap: 15px;
    padding: 20px;
  }
`;

export const P = styled.p`
  ${fontStyles.regularSmall}
  color: var(--color-grey-94);
`;
