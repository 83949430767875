import styled from 'styled-components';

import Select from 'components/Select';

export const Container = styled.div`
  width: 140px;
  @media (max-width: 1023px) {
    width: 100%;
    margin: 20px 0 5px 0;
  }
`;

export const Dropdown = styled(Select).attrs(() => ({
  color: 'var(--color-primary)',
  arrowWidth: 30,
}))`
  height: 36px;
  border-color: var(--color-primary);
  padding: 0 30px 0 8px;
`;
