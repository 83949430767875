import { useState, useRef, useCallback, useEffect, useMemo, useContext } from 'react';
import { useDispatch as useDefDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { cond, is, path, pipe, propOr, T, props, prop, equals, pathOr, when, map } from 'ramda';

import { GET_USER, GET_USER_ID, GET_PRODUCTS } from 'containers/authorization/api';
import { useQuery } from 'api';
import client from 'api/graphql';
import { handleCurrency, handlePrice } from 'utils';
import { AppContext } from 'components/Root/contexts';

export const useAsyncState = (init) => {
  const isMounted = useRef();
  const [state, setState] = useState(init);

  const handleChange = useCallback((arg) => {
    if (isMounted.current) {
      setState(arg);
    }
  }, []);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return [state, handleChange, isMounted];
};

export const useVoucherFromUrl = () => {
  const [searchParams] = useSearchParams();

  return searchParams.get('voucher') || '';
};

export const useDispatch = (actionCreator) => {
  const dispatch = useDefDispatch();

  return useCallback((payload) => dispatch(actionCreator(payload)), [dispatch, actionCreator]);
};

export const useUser = (fields) => {
  const { data: userIdData } = useQuery(GET_USER_ID, { fetchPolicy: 'cache-only' });
  const { data } = useQuery(GET_USER, {
    fetchPolicy: 'cache-only',
    variables: { id: userIdData?.currentUserId },
    skip: !userIdData?.currentUserId,
  });
  const refData = useRef(data);

  const getResult = useCallback(
    (f = null, d = {}) =>
      cond([
        [() => is(String, f), () => path(['user', f], d)],
        [() => is(Array, f), () => pipe(propOr({}, 'user'), props(f))(d)],
        [T, () => prop('user', d)],
      ])(f, d),
    []
  );
  const refResult = useRef(getResult(fields, data));

  if (!equals(refData.current, data)) {
    refData.current = data;
    refResult.current = getResult(fields, data);
  }

  return refResult.current;
};

export const useUserActions = () => {
  const saveCurrentUser = useCallback((data) => {
    if (!(data?.id && client)) return;

    client.writeQuery({
      query: GET_USER_ID,
      data: { currentUserId: data.id },
    });

    client.writeQuery({
      query: GET_USER,
      variables: { id: data.id },
      data: { user: data },
    });
  }, []);
  const deleteCurrentUser = useCallback(() => {
    const { currentUserId } = client.readQuery({ query: GET_USER_ID }) || {};

    if (!(currentUserId && client?.cache)) return;

    client.cache.evict({
      id: 'ROOT_QUERY',
      field: 'user',
      args: { id: currentUserId },
    });
  }, []);

  return { saveCurrentUser, deleteCurrentUser };
};

const handleData = when(Boolean, ({ currency, price, period, ...rest }) => ({
  ...rest,
  period,
  rawCurrency: currency,
  currency: handleCurrency(currency),
  rawPrice: price,
  price: handlePrice(price),
  unitPrice: handlePrice(price / period),
}));

export const useProducts = () => {
  const { data, loading, error } = useQuery(GET_PRODUCTS);
  const product = useMemo(() => pipe(pathOr({}, ['app', 'product']), handleData)(data || {}) || {}, [data]);
  const products = useMemo(() => pipe(pathOr([], ['app', 'products']), when(Boolean, map(handleData)))(data || {}) || [], [data]);

  return {
    loading,
    error,
    product,
    products,
  };
};

export const useAppContext = (init) => {
  const timer = useRef();
  const [state, setState] = useAsyncState();

  const handleChange = useCallback(
    (value) => {
      setState(value);

      if (timer.current) clearTimeout(timer.current);

      timer.current = setTimeout(() => {
        setState();
        timer.current = null;
      }, 5000);
    },
    [setState]
  );

  const initContext = useMemo(() => [state, handleChange], [state, handleChange]);

  const context = useContext(AppContext);

  return init ? initContext : context;
};

export const useMobileDetect = () => {
  const mediaQuery = useMemo(() => window.matchMedia('(max-width: 1023px)'), []);
  const [isMobile, setIsMobile] = useAsyncState(mediaQuery.matches);

  const handleResize = useCallback(({ matches }) => setIsMobile(matches), [setIsMobile]);

  useEffect(() => {
    if (mediaQuery.addEventListener) {
      mediaQuery.addEventListener('change', handleResize);
    } else {
      mediaQuery.addListener(handleResize);
    }

    return () => {
      if (mediaQuery.removeEventListener) {
        mediaQuery.removeEventListener('change', handleResize);
      } else {
        mediaQuery.removeListener(handleResize);
      }
    };
  }, [handleResize, mediaQuery]);

  return isMobile;
};
