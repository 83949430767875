export const API_URL = process.env.REACT_APP_API_URL;
export const API_URL_GRAPHQL = process.env.REACT_APP_API_URL_GRAPHQL;
export const API_URL_REST = process.env.REACT_APP_API_URL_REST;
export const API_TOKEN_REST = process.env.REACT_APP_API_TOKEN_REST;
export const API_WS_URL = process.env.REACT_APP_API_WS_URL;
export const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY;
export const BUGSNAG_API_KEY = '2bc77e96de2b079adffb9cad994727eb';
export const APP_TOKEN = 'b5cc5b38fee54c69d20f417790c44b8d';

export const INTRO_VIDEO_URL = 'https://stream.mux.com/00fqoTY9KiPJm4Y00XeFrWXnNkp901NK3D4ox3bMuoYalI.m3u8';

export const NETWORK_ERROR = 'Oops, an error has occurred. Check your Internet connection.';
export const SESSION_ERROR = 'Your session has expired. Sign in again.';
export const INTERNAL_SERVER_ERROR = 'Oops, an error has occurred.';
export const DEFAULT_ERROR_MESSAGE_SHORT = 'Oops, an error has occurred.';
export const DEFAULT_ERROR_MESSAGE =
  'Oops, an error has occurred. Help us improve your user experience and send us a bug report.';
