import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './locale';
import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga4';
// import ReactPixel from 'react-facebook-pixel';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import apolloClient from 'api/graphql';
import { BUGSNAG_API_KEY } from 'api/constants';
import store from 'store';
import Root from 'components/Root';
import FallbackComponent from 'components/ErrorPage';
import packageJson from '../package.json';

const isProduction = process.env.REACT_APP_ENV === 'production';
const isStaging = process.env.REACT_APP_ENV === 'staging';

if (isProduction || isStaging) {
  Bugsnag.start({
    apiKey: BUGSNAG_API_KEY,
    appType: 'client',
    appVersion: packageJson.version,
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ['production'],
  });
}

const ErrorBoundary = isProduction || isStaging ? Bugsnag.getPlugin('react').createErrorBoundary(React) : Fragment;
const bugsnagProps = isProduction || isStaging ? { FallbackComponent } : {};

ReactDOM.render(
  <ErrorBoundary {...bugsnagProps}>
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <BrowserRouter>
          <Root />
        </BrowserRouter>
      </Provider>
    </ApolloProvider>
  </ErrorBoundary>,
  document.getElementById('root')
);

if (isProduction) {
  ReactGA.initialize('G-MNTYT9X55B', { gtagOptions: { anonymize_ip: true } });
  ReactGA.send('pageview');
  // ReactPixel.init('392213878723681');
}
