import styled from 'styled-components';

import { fontStyles } from 'theme';
import DefSpinner from 'components/Spinner';

const getColor = ({ error, red, green, black }) => {
  if (error || red) return 'var(--color-primary)';
  if (green) return 'var(--color-green)';
  if (black) return 'var(--color-black)';

  return 'var(--color-primary)';
};

const getColorActive = ({ error, red, green }) => {
  if (error || red) return 'var(--color-primary)';
  if (green) return 'var(--color-green)';

  return 'var(--color-primary)';
};

export const Button = styled.button`
  ${fontStyles.regularSmall}
  width: 100%;
  height: 50px;
  margin-bottom: 15px;
  outline: none;
  cursor: pointer;
  transition: all 0.5s;
  border-radius: var(--border-radius);
  border: 1px solid ${getColor};
  background: var(--color-white);
  color: ${getColor};
  &:active:enabled {
    color: var(--color-white);
    border: 1px solid ${getColorActive};
    background: ${getColorActive};
  }
  &:disabled {
    color: var(--color-grey-94);
    border: 1px solid var(--color-grey-94);
    cursor: not-allowed;
  }
  @media (max-width: 1023px) {
    &:disabled {
      color: var(--color-black);
      border: 1px solid var(--color-grey-94);
    }
  }
  @media (min-width: 1024px) and (hover) {
    &:hover {
      color: var(--color-white);
      background: ${getColorActive};
      border: 1px solid ${getColorActive};
      &:disabled {
        color: var(--color-grey-94);
        border: 1px solid var(--color-grey-94);
        &:hover {
          background: var(--color-white);
        }
      }
    }
  }
`;

export const Spinner = styled(DefSpinner)`
  margin: 0;
`;
