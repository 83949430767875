import { ApolloClient, createHttpLink, InMemoryCache, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { propOr } from 'ramda';

import i18n from 'locale';
import { DEFAULT_LANGUAGE } from 'locale/consts';

import { localStorage } from './storages';
import { API_URL_GRAPHQL, APP_TOKEN, API_WS_URL } from './constants';

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    'x-token': localStorage.getItem('token') || '',
    'app-token': APP_TOKEN || '',
    'accept-language': i18n.language || DEFAULT_LANGUAGE,
  },
}));

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        posts: {
          keyArgs: ['category', 'sortParam', 'sort', 'keyword', 'search'],
          merge(existing, incoming, { args }) {
            if (!args?.cursor) return incoming;

            return {
              ...incoming,
              edges: [...propOr([], 'edges', existing || {}), ...incoming.edges],
            };
          },
        },
      },
    },
  },
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: API_WS_URL,
    connectionParams: {
      'x-token': localStorage.getItem('token') || '',
      'app-token': APP_TOKEN || '',
    },
  })
);

export default new ApolloClient({
  cache,
  link: split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    authLink.concat(createHttpLink({ uri: API_URL_GRAPHQL }))
  ),
});
