const createStorage = (name) => {
  const dummyStorage = { [name]: {} };

  const showMessage = (e) => console.error(`${name} is unavailable`, e); // eslint-disable-line no-console
  const setItem = (key, value) => {
    try {
      window[name].setItem(key, JSON.stringify(value));
    } catch (e) {
      dummyStorage[name][key] = value;
      showMessage(e);
    }
    return value;
  };
  const getItem = (key) => {
    try {
      const data = window[name].getItem(key);
      return JSON.parse(data);
    } catch (e) {
      showMessage(e);
      return dummyStorage[name][key];
    }
  };
  const removeItem = (key) => {
    try {
      window[name].removeItem(key);
    } catch (e) {
      delete dummyStorage[name][key];
      showMessage(e);
    }
  };
  const clear = () => {
    try {
      window[name].clear();
    } catch (e) {
      dummyStorage[name] = {};
      showMessage(e);
    }
  };

  return { setItem, getItem, removeItem, clear };
};

const localStorage = createStorage('localStorage');
const sessionStorage = createStorage('sessionStorage');

export { localStorage, sessionStorage };
