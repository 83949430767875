import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { localStorage } from 'api/storages';

import { Container, Text, Link, ButtonWrap, Button } from './styles';

const CookieWarn = () => {
  const [hideWarn, setHideWarn] = useState(localStorage.getItem('cookieAgreement'));
  const { t } = useTranslation('app');
  const navigate = useNavigate();

  const handleClickAgree = useCallback(() => {
    localStorage.setItem('cookieAgreement', true);
    setHideWarn(true);
  }, []);
  const handleClickMore = useCallback(() => navigate('/datenschutz'), [navigate]);

  if (hideWarn) return null;

  return (
    <Container>
      <div>
        <Text>
          {t('We use cookies to improve your user experience.')} <br />
          {t('You can learn more about our cookie policy')} <Link to="/datenschutz">{`${t('here')}.`}</Link>
        </Text>
      </div>
      <ButtonWrap>
        <Button type="button" onClick={handleClickAgree} red>
          {t('Agree')}
        </Button>
        <Button type="button" onClick={handleClickMore} black>
          {t('More')}
        </Button>
      </ButtonWrap>
    </Container>
  );
};

export default CookieWarn;
