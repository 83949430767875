import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  @font-face {
    font-display: swap;
    font-family: Okomito-Regular;
    src: url(/fonts/Okomito-Regular.woff) format("woff"), url(/fonts/Okomito-Regular.woff) format("woff2");
  }
  @font-face {
    font-display: swap;
    font-family: Okomito-Medium;
    src: url(/fonts/Okomito-Medium.woff) format("woff"), url(/fonts/Okomito-Medium.woff) format("woff2");
  }
  * {
    margin: 0;
    padding: 0;
    border: none;
    font-size: 15px;
    line-height: normal;
    font-style: normal;
    font-stretch: normal;
    font-family: Okomito-Regular, sans-serif;
    color: var(--color-black);
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-overflow-scrolling: touch;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
  }
  html {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 350px;
  }
  body {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    background: var(--color-white);
  }
  #root {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }
  li {
    list-style: none;
  }
  button::-moz-focus-inner {
    border: 0;
  }
  a::-moz-focus-inner {
    border: 0;
  }
  a, button {
    outline: none;
  }
  @media (max-width: 1023px) {
    input, textarea {
      font-size: 16px !important;
    }
  }
  :root {
    --color-white: #fff;
    --color-black: #000;
    --color-primary: #fd4f57;
    --color-green: #65d8a2;
    --color-blue: #3e64fb;
    --color-blue-active: #1040ff;
    --color-blue-cover: #67a9ff;
    --color-pink: #ffebeb;
    --color-grey-1d: #1d1d1d;
    --color-grey-4f: #4f4f4f;
    --color-grey-94: #949494;
    --color-grey-de: #dedede;
    --color-grey-d8: #d8d8d8;
    --color-grey-e9: #e9e9e9;
    --color-grey-f0: #f0f0f0;
    --color-grey-f4: #f4f4f4;
    --color-grey-f8: #f8f8f8;

    --color-red-f9: #f94c4a;
    --color-blue-d4: #d4dfeb;

    --border-radius: 8px;
    --header-height: 50px;
  }
`;
