import styled from 'styled-components';

import { fontStyles } from 'theme';
import Search from 'assets/svg/Search';
import Cross from 'assets/svg/Cross';

export const Container = styled.div`
  justify-self: end;
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  column-gap: 2px;
  width: 100%;
  max-width: 275px;
  height: 33px;
  position: relative;
  z-index: 100;
  border: 1px solid var(--color-primary);
  border-radius: var(--border-radius);
  background-color: ${({ $isFocused }) => ($isFocused ? 'var(--color-white)' : 'var(--color-primary)')};
  padding: 0 5px 0 12px;
  @media (max-width: 1023px) {
    grid-template-columns: 1fr 38px;
    column-gap: 9px;
    height: 50px;
    margin: 0 auto;
    width: calc(100% - 40px);
    max-width: none;
    background-color: var(--color-white);
    padding: 0 17px;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  background: none;
  &::placeholder {
    color: var(--color-white);
    text-align: center;
  }
  @media (max-width: 1023px) {
    grid-column: 1/2;
    grid-row: 1/2;
    ${fontStyles.regularSmall}
    &::placeholder {
      color: var(--color-grey-94);
      text-align: left;
    }
  }
`;

export const SearchIcon = styled(Search)`
  width: 24px;
  height: 24px;
  grid-column: 3/4;
  grid-row: 1/2;
  & > g {
    stroke: ${({ $isFocused }) => ($isFocused ? 'var(--color-primary)' : 'var(--color-white)')};
  }
  @media (max-width: 1023px) {
    width: 38px;
    height: 38px;
    & > g {
      stroke: var(--color-primary);
    }
  }
`;

export const Button = styled.button`
  width: 24px;
  height: 24px;
  background: none;
  cursor: pointer;
  @media (max-width: 1023px) {
    grid-column: 2/3;
    grid-row: 1/2;
    width: 38px;
    height: 38px;
  }
`;

export const CloseIcon = styled(Cross)`
  width: 100%;
  height: 100%;
`;
