import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { useAsyncState } from 'hooks';
import { updateFilters } from 'containers/posts/actions';
import { getFilter } from 'containers/posts/selectors';

import { Container, Input, SearchIcon, Button, CloseIcon } from './styles';

const SearchInput = () => {
  const timer = useRef();
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isVideosPage = pathname === '/videos';

  const initText = useSelector(getFilter('search'));
  const [text, setText] = useAsyncState(initText);
  const hasSearch = Boolean(text);

  const dispatch = useDispatch();
  const handleChange = useCallback(
    ({ target }) => {
      setText(target.value);
      if (timer.current) clearTimeout(timer.current);

      timer.current = setTimeout(
        (arg) => {
          dispatch(updateFilters({ search: arg }));

          if (arg && !isVideosPage) navigate('/videos');

          timer.current = null;
        },
        500,
        target.value
      );
    },
    [dispatch, isVideosPage, navigate, setText]
  );
  const handleReset = useCallback(() => {
    setText('');

    if (timer.current) clearTimeout(timer.current);

    dispatch(updateFilters({ search: '' }));
  }, [dispatch, setText]);

  const [isFocused, setIsFocused] = useState(false);
  const onFocus = useCallback(() => setIsFocused(true), []);
  const onBlur = useCallback(() => setIsFocused(false), []);

  useEffect(() => {
    if (!timer.current) setText(initText);
  }, [initText, setText]);

  useEffect(
    () => () => {
      if (timer.current) clearTimeout(timer.current);
    },
    []
  );

  return (
    <Container $isFocused={hasSearch || isFocused}>
      <Input type="text" value={text} onChange={handleChange} onFocus={onFocus} onBlur={onBlur} placeholder={t('Search video')} />
      {hasSearch && (
        <Button type="button" onClick={handleReset}>
          <CloseIcon />
        </Button>
      )}
      <SearchIcon $isFocused={hasSearch || isFocused} />
    </Container>
  );
};

export default SearchInput;
