import styled from 'styled-components';
import { Link as RLink } from 'react-router-dom';

import { fontStyles } from 'theme';
import MobileDropDown from 'components/MobileDropDown';

export const Footer = styled.footer`
  width: 100%;
  border-top: 1px solid var(--color-primary);
`;

export const Container = styled.footer`
  width: 100%;
  padding: 12px 20px 15px 20px;
`;

export const DropDown = styled(MobileDropDown)`
  margin-top: 8px;
`;

export const Content = styled.div`
  margin-bottom: 8px;
`;

const link = `
  ${fontStyles.regularSmall}
  display: block;
  line-height: 1.67;
  text-decoration: none;
  &:active {
    color: var(--color-primary);
  }
`;

export const Link = styled(RLink)`
  ${link}
`;

export const CopyRight = styled.p`
  line-height: 1.4;
  letter-spacing: 0.26px;
  color: rgba(197, 30, 29, 0.42);
  margin-top: 10px;
`;

export const A = styled.a`
  ${link}
`;

export const Wrapper = styled.div`
  width: 100%;
  margin: 20px 0 5px 0;
`;
