import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Wrapper, LinkContainer } from './styles';

const NavButton = ({ name, link, matches, isHightLight, isExternal, onClick }) => {
  const { pathname } = useLocation();
  const isActive = useMemo(
    () => matches?.find((path) => (path === '/' ? path === pathname : new RegExp(path).test(pathname))),
    [matches, pathname]
  );

  return (
    <Wrapper $isActive={isActive} $isHightLight={isHightLight}>
      <LinkContainer to={link} onClick={onClick} $isActive={isActive} $isHightLight={isHightLight} $isExternal={isExternal}>
        {name}
      </LinkContainer>
    </Wrapper>
  );
};

NavButton.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  link: PropTypes.string.isRequired,
  matches: PropTypes.arrayOf(PropTypes.string),
  isHightLight: PropTypes.bool,
  isExternal: PropTypes.bool,
  onClick: PropTypes.func,
};

NavButton.defaultProps = {
  matches: null,
  isHightLight: false,
  isExternal: false,
  onClick: () => null,
};

export default NavButton;
