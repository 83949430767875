import styled from 'styled-components';
import { motion } from 'framer-motion';

import { fontStyles } from 'theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Button = styled.button`
  border: none;
  outline: none;
  background: none;
  border-bottom: ${({ footer }) => (footer ? 'none' : `1px solid var(--color-grey-94)`)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${({ footer }) => (footer ? '35px' : '50px')};
  padding: ${({ footer }) => (footer ? 0 : '0 15px')};
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  ${({ footer }) =>
    footer
      ? `
      ${fontStyles.regularSmall}
      color: var(--color-primary);
      line-height: 1.33;
    `
      : `
      ${fontStyles.regular}
      letter-spacing: 0.3px;
    `}
`;

export const Box = styled(motion.div).attrs(({ $isOpen }) => ({
  animate: { height: $isOpen ? 'auto' : 0, marginBottom: $isOpen ? 8 : 0, overflow: $isOpen ? 'auto' : 'hidden' },
  transition: {
    height: { duration: 0.3 },
    marginBottom: { duration: 0.3 },
    overflow: { duration: 0, delay: $isOpen ? 0 : 0.3 },
  },
  initial: { height: $isOpen ? 'auto' : 0, marginBottom: $isOpen ? 8 : 0, overflow: $isOpen ? 'auto' : 'hidden' },
}))`
  border-bottom: ${({ $footer }) => ($footer ? '1px solid rgba(249, 80, 80, 0.4)' : 'none')};
  overflow: hidden;
  position: relative;
`;
