import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Container, StyledSelect, Icon } from './styles';

const Select = ({ value, values, placeholder, placeholderColor, color, arrowWidth, ...rest }) => {
  const { t } = useTranslation('app');

  return (
    <Container>
      <StyledSelect value={value} $color={value === '' ? placeholderColor : color} $arrowWidth={arrowWidth} {...rest}>
        <option value="" label={t(placeholder)} disabled>
          {t(placeholder)}
        </option>
        {values.map(({ id, name }) => (
          <option key={id} value={id} label={name}>
            {name}
          </option>
        ))}
      </StyledSelect>
      <Icon $color={color} $arrowWidth={arrowWidth} />
    </Container>
  );
};

Select.defaultProps = {
  value: '',
  values: [],
  placeholder: 'Choose',
  placeholderColor: 'var(--color-black)',
  color: 'var(--color-black)',
  arrowWidth: 46,
};

Select.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }).isRequired
  ),
  placeholder: PropTypes.string,
  placeholderColor: PropTypes.string,
  color: PropTypes.string,
  arrowWidth: PropTypes.number,
};

export default Select;
