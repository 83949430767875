import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Container, Button, Text, Icon, Content } from './styles';

const Dropdown = ({ name, isActive, width, children }) => {
  const [isOpen, setIsOpen] = useState();
  const handleClick = useCallback(() => setIsOpen(($) => !$), []);

  const handleClose = useCallback(() => setIsOpen(false), []);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleClose);

      return () => document.removeEventListener('click', handleClose);
    }

    return () => null;
  }, [handleClose, isOpen]);

  return (
    <Container>
      <Button type="button" onClick={handleClick} isOpen={isOpen} isActive={isActive} width={width}>
        <Text>{name}</Text>
        <Icon isOpen={isOpen} isActive={isActive} />
      </Button>
      <Content $isOpen={isOpen}>{children}</Content>
    </Container>
  );
};

Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default Dropdown;
