import { useEffect } from 'react';

export const useCheck = (isSubscribed, isIntro, isLoggedIn, setIsShow) => {
  useEffect(() => {
    if (!isIntro && isLoggedIn && (isLoggedIn || !isSubscribed)) {
      setIsShow(true);
    }
    if (isIntro || (isLoggedIn && isSubscribed)) {
      setIsShow(false);
    }
  }, [isSubscribed, isIntro, setIsShow, isLoggedIn]);
};
