import React from 'react';
import PropTypes from 'prop-types';

import { Button, Spinner } from './styles';

const AsyncButton = ({ loading, disabled, children, ...rest }) => (
  <Button disabled={disabled || loading} {...rest}>
    {loading ? <Spinner height={50} size={30} /> : children}
  </Button>
);

AsyncButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

AsyncButton.defaultProps = {
  loading: false,
  disabled: false,
};

export default AsyncButton;
