import React from 'react';
import { useTranslation } from 'react-i18next';

import Bankenverband from 'assets/svg/Bankenverband';
import SwissLife from 'assets/svg/SwissLife';

import { Container, P } from './styles';

const Sponsors = () => {
  const { t } = useTranslation('app');

  return (
    <Container>
      <P>{t('Sponsors')}</P>
      <a target="_blank" href="https://zuercher-bankenverband.ch" rel="noreferrer" aria-label="zuercher-bankenverband">
        <Bankenverband />
      </a>
      <a target="_blank" href="https://www.swisslife.ch" rel="noreferrer" aria-label="swisslife">
        <SwissLife />
      </a>
    </Container>
  );
};

export default Sponsors;
