/* eslint-disable import/no-dynamic-require */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import { cond, T, pipe, take, __, includes } from 'ramda';

import { DEFAULT_LANGUAGE, LANG_LIST, LOCALE_LIST } from './consts';

const resources = {};

LANG_LIST.forEach((lng) => {
  if (!resources[lng]) {
    resources[lng] = {};
  }
  LOCALE_LIST.forEach((locale) => {
    resources[lng][locale] = require(`./${locale}/${lng}`);
  });
});

const checkLanguage = () => {
  cond([
    [includes(__, LANG_LIST), T],
    [pipe(take(2), includes(__, LANG_LIST)), pipe(take(2), i18n.changeLanguage.bind(i18n))],
    [T, () => i18n.changeLanguage(DEFAULT_LANGUAGE)],
  ])(i18n.language);
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init(
    {
      resources,
      fallbackLng: DEFAULT_LANGUAGE,
      debug: process.env.REACT_APP_ENV === 'development',
      keySeparator: false,
      nsSeparator: ':',
      interpolation: {
        escapeValue: false,
      },
    },
    checkLanguage
  );

export default i18n;
