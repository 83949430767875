import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

import Spinner from 'components/Spinner';
import ErrorBoundary from '../SuspenseErrorBoundary';

const PublicRoute = ({ component: Component }) => (
  <ErrorBoundary>
    <Suspense fallback={<Spinner />}>
      <Component />
    </Suspense>
  </ErrorBoundary>
);

PublicRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default PublicRoute;
