import styled from 'styled-components';

import { fontStyles } from 'theme';
import Arrow from 'assets/svg/SelectArrows';

export const Container = styled.div`
  width: 100%;
  position: relative;
`;

export const StyledSelect = styled.select`
  width: 100%;
  height: 50px;
  border: 1px solid var(--color-grey-94);
  border-radius: var(--border-radius);
  ${fontStyles.regularSmall}
  color: ${({ $color }) => $color};
  padding: ${({ $arrowWidth }) => `0 ${$arrowWidth}px 0 15px`};
  background: transparent;
  outline: none;
  cursor: pointer;
`;

export const Icon = styled(Arrow)`
  position: absolute;
  top: 50%;
  right: ${({ $arrowWidth }) => `calc((${$arrowWidth}px - 15px) / 2)`};
  width: 15px;
  transform: translate(0, -50%);
  pointer-events: none;
  & > g {
    fill: ${({ $color }) => $color};
  }
`;
