import React, { useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Container } from './styles';

const Main = ({ children }) => {
  const { pathname } = useLocation();
  const resetScrollAllowed = useMemo(() => Boolean(window.scrollTo) && !pathname.includes('/support'), [pathname]);

  useEffect(() => {
    if (resetScrollAllowed) window.scrollTo(0, 0);
  }, [resetScrollAllowed, pathname]);

  return <Container>{children}</Container>;
};

Main.defaultProps = {
  children: null,
};

Main.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

export default Main;
