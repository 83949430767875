import { pipe, split, tail, filter, reduce, path } from 'ramda';

import i18n from 'locale';

import { DEFAULT_DESCRIPTION } from './consts';

export const splitPathname = pipe(split('/'), tail, filter(Boolean));

const getTitle = (locale) => `Fintool | ${i18n.t(locale)}`;

export const getStaticMetaData = () => {
  const description = i18n.t(`app:${DEFAULT_DESCRIPTION}`);

  return {
    default: {
      title: getTitle('courses:Finance knowledge for everyone'),
      description,
    },
    anmelden: {
      title: getTitle('app:Login'),
      description,
    },
    registrieren: {
      title: getTitle('app:Register'),
      description,
    },
    'account-aktivieren': {
      title: getTitle('authorization:Activate account?'),
      description,
    },
    'passwort-vergessen': {
      title: getTitle('authorization:Forgot your password?'),
      description,
    },
    'passwort-setzen': {
      title: getTitle('app:Set password'),
      description,
    },
    account: {
      title: getTitle('app:Account'),
      description,
    },
    'meine-kurse': {
      title: getTitle('app:My courses'),
      description,
    },
    impressum: {
      title: getTitle('app:Imprint'),
      description,
    },
    support: {
      title: getTitle('app:Support'),
      description,
    },
    datenschutz: {
      title: getTitle('app:Data protection'),
      description,
    },
    agb: {
      title: getTitle('app:Terms and Conditions'),
      description,
    },
    team: {
      title: getTitle('app:Team'),
      description,
    },
    medien: {
      title: getTitle('app:Media'),
      description,
    },
    partner: {
      title: getTitle('app:Partner'),
      description,
    },
    events: {
      title: getTitle('app:Events'),
      description: i18n.t(
        'app:Fintool is on the way. Have you always wanted to hear from an independent and neutral source about how to invest money? Fintool is known for explaining complex relationships in a simple and humorous way - get to know us.'
      ),
    },
    akademie: {
      title: getTitle('app:Academy'),
      description,
    },
    videos: {
      title: getTitle('app:Video studio'),
      description: i18n.t(
        'app:The video studio is our archive with over 900 individual videos. An intelligent search helps you to find the video you want. You can rate videos and discuss the content with our experts and other users. For advanced users, a separate video category offers technical background on important investment and financial topics.'
      ),
    },
    books: {
      title: getTitle('app:Bookshop'),
      description: i18n.t(
        'app:Discover the popular Fintool books on topics such as investing, retirement planning and inflation'
      ),
    },
    'suche-nach-reichtum': {
      title: getTitle('app:In search of wealth'),
      description: i18n.t('app:Revelations from the world of risks and returns'),
    },
    waehrungen: {
      title: getTitle('app:Currencies and exchange rates'),
      description: i18n.t('app:What to look for in foreign currency investments'),
    },
    'die-aktienboerse': {
      title: getTitle('app:The stock exchange'),
      description: i18n.t('app:How it works and why forecasts make no sense'),
    },
    'wenn-banken-wanken': {
      title: getTitle('app:When banks falter'),
      description: i18n.t('app:Banking knowledge for home use'),
    },
    'generation-y': {
      title: getTitle('app:Investing money for Generation Y'),
      description: i18n.t('app:We show what this means and how you can take advantage of these benefits'),
    },
    inflation: {
      title: getTitle('app:Inflation is back'),
      description: i18n.t(
        'app:In connection with the Corona crisis and the war in Ukraine, the fundamental-economic mixed bag regarding inflation has changed'
      ),
    },
    'corona-crash': {
      title: getTitle('app:History rhymes - also on the stock market'),
      description: i18n.t(
        'app:Winston Churchill is said to have said «Never let a good crisis go to waste». And Mark Twain once said that history may not repeat itself, but it does rhyme. These two bon mots were the inspiration for the idea of writing a «SmartBook» about the effects of the Corona crisis on the financial and stock markets.'
      ),
    },
    moneyfactory: {
      title: getTitle('app:Money Factory and pension provision in Switzerland'),
      description: i18n.t(
        'app:The Money Mactory defines a pattern of thinking for strategic investment, which describes the savings and investment process over the life cycle.'
      ),
    },
    'money-combo': {
      title: getTitle('app:Money-Combo-1'),
      description: i18n.t('app:In search of wealth - revelations from the world of risk and return'),
    },
    'money-combo-2': {
      title: getTitle('app:Money-Combo-2'),
      description: i18n.t('app:Voucher for a Premium annual subscription'),
    },
    'money-combo-3': {
      title: getTitle('app:Money-Combo-3'),
      description: i18n.t('app:Voucher for a one-year Premium subscription'),
    },
    'die-schweiz-sorgt-vor': {
      title: getTitle('app:Switzerland takes care'),
      description: i18n.t('app:The three pillars of retirement provision'),
    },
    'bitcoin-krypto': {
      title: getTitle('app:Bitcoin & crypto'),
      description: i18n.t('app:Do cryptos fit into my portfolio'),
    },
    'saq-suche-nach-reichtum': {
      title: getTitle('app:In search of wealth2'),
      description: i18n.t('app:Revelations from the world of risks and returns'),
    },
    geschenkkarte: {
      title: getTitle('app:Gift card'),
      description: i18n.t(
        'app:Because knowledge still pays the best returns, we offer you the opportunity to give returns as a gift. Give the gift of a Fintool annual subscription now.'
      ),
    },
    'glaenzende-aussichten': {
      title: getTitle('app:Brilliant prospects'),
      description: i18n.t('app:Why share prices will double - and when'),
    },
    business: {
      title: 'Fintool | Für Geschäftskunden',
      description:
        'Fintool hilft Geschäftskunden auf einfache Art und Weise, vertieftes Finanz- und Wirtschaftswissen zu erhalten, um marktrelevante Ereignisse zu beurteilen und für die berufliche Arbeit adäquat umzusetzen.',
    },
    b2b: {
      title: 'Fintool | Für Geschäftskunden',
      description:
        'Fintool hilft Geschäftskunden auf einfache Art und Weise, vertieftes Finanz- und Wirtschaftswissen zu erhalten, um marktrelevante Ereignisse zu beurteilen und für die berufliche Arbeit adäquat umzusetzen.',
    },
    videoletter: {
      title: 'Fintool | Videoletter',
      description,
    },
  };
};

export const normalize = reduce((acc, item = {}) => {
  const { id, title, name, description, content } = item;

  acc[id] = {
    title: title || name ? `Fintool | ${title || name}` : path(['default', 'title'], getStaticMetaData()),
    description: description || content || title || '',
  };
  return acc;
}, {});
