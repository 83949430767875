import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Button } from './styles';

const LogoutButton = ({ name, logout, isDropdown, ...rest }) => (
  <Wrapper isDropdown={isDropdown} {...rest}>
    <Button type="button" onClick={logout} isDropdown={isDropdown}>
      {name}
    </Button>
  </Wrapper>
);

LogoutButton.defaultProps = { isDropdown: false };
LogoutButton.propTypes = {
  name: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
  isDropdown: PropTypes.bool,
};

export default LogoutButton;
