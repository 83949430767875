import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/ArrowIcon';

import { Container, Button, Title, Box } from './styles';

const MobileDropDown = ({ title, footer, children, className, defaultOpen }) => {
  const [open, setOpen] = useState(defaultOpen);
  const handleClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <Container className={className}>
      <Button type="button" onClick={handleClick} footer={footer} open={open}>
        <div>
          <Title footer={footer}>{title}</Title>
        </div>
        <Icon isOpen={open} color={footer ? 'var(--color-primary)' : 'var(--color-black)'} />
      </Button>
      <Box $isOpen={open} $footer={footer}>
        {children}
      </Box>
    </Container>
  );
};

MobileDropDown.defaultProps = {
  footer: false,
  className: '',
  defaultOpen: false,
};

MobileDropDown.propTypes = {
  title: PropTypes.string.isRequired,
  footer: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  className: PropTypes.string,
  defaultOpen: PropTypes.bool,
};

export default MobileDropDown;
