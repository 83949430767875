import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd" stroke="var(--color-white)" transform="translate(1 1)">
      <g strokeLinecap="square">
        <path d="M5.25 5.25l7.5 7.5M5.25 12.75l7.5-7.5" />
      </g>
      <circle cx="9" cy="9" r="9" />
    </g>
  </svg>
);
