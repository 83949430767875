import styled from 'styled-components';
import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';

import { fontStyles } from 'theme';

const style = `
  ${fontStyles.title}
  font-size: 29px;
  height: 100%;
  width: 100%;
  padding: 25px;
`;

export const Container = styled(motion.div).attrs(() => ({
  variants: {
    open: { x: 0, opacity: 1 },
    close: { x: 100, opacity: 0 },
  },
}))`
  width: 100%;
  border-bottom: 1px solid var(--color-primary);
  background-color: ${({ $isHightLight, $isActive }) =>
    $isHightLight && !$isActive ? 'var(--color-primary)' : 'var(--color-white)'};
  &:active {
    background-color: var(--color-white);
  }
`;

export const Link = styled(NavLink).attrs(({ $isExternal }) =>
  $isExternal
    ? {
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : {}
)`
  ${style}
  display: inline-block;
  color: ${({ $isHightLight, $isActive }) => {
    if ($isActive) return 'var(--color-primary)';
    if ($isHightLight) return 'var(--color-white)';

    return 'var(--color-black)';
  }};
  text-decoration: none;
  &:active {
    color: var(--color-primary);
  }
`;

export const Button = styled.button`
  text-align: left;
  outline: none;
  border: none;
  background: none;
  ${style}
  &:active {
    color: var(--color-primary);
  }
`;
