import featureActionName from 'store/utils';

import { FEATURE_NAME } from './consts';

export const dateWithoutTime = (date) => {
  const res = new Date(date);

  res.setHours(0);
  res.setMinutes(0);
  res.setSeconds(0);
  res.setMilliseconds(0);

  return Date.parse(res);
};

export const actionName = featureActionName(FEATURE_NAME);
