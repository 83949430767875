import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet as RHelmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { DEFAULT_LANGUAGE } from 'locale/consts';

import { useMetaData } from './hooks';

const Helmet = () => {
  const { key } = useLocation();
  const [initKey] = useState(key);
  const { i18n } = useTranslation();
  const { title, description } = useMetaData();

  if (initKey === key && i18n.language === DEFAULT_LANGUAGE) return null;

  return (
    <RHelmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
    </RHelmet>
  );
};

export default Helmet;
