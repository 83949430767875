import styled from 'styled-components';
import { motion } from 'framer-motion';

const style = `
  position: absolute;
  bottom: 8px;
  width: 16px;
  height: 1px;
`;

export const Container = styled.div`
  position: relative;
  width: 22px;
  height: 16px;
`;

export const LeftLine = styled(motion.div).attrs(({ $isOpen }) => ({
  animate: {
    left: $isOpen ? [-2.999, 3, -3] : [-3, 3, -2.999],
    rotate: $isOpen ? [45, 90, 135] : [135, 90, 45],
    borderRadius: $isOpen ? [0.001, 1, 0] : [0, 1, 0.001],
  },
  transition: {
    duration: 0.3,
    times: [0, 0.5, 1],
  },
  initial: false,
}))`
  ${style}
  background: ${({ $color }) => $color};
`;

export const RightLine = styled(motion.div).attrs(({ $isOpen }) => ({
  animate: {
    left: $isOpen ? [7.999, 3, 8] : [8, 3, 7.999],
    rotate: $isOpen ? [135, 90, 45] : [45, 90, 135],
    borderRadius: $isOpen ? [0.001, 1, 0] : [0, 1, 0.001],
  },
  transition: {
    duration: 0.3,
    times: [0, 0.5, 1],
  },
  initial: false,
}))`
  ${style}
  background: ${({ $color }) => $color};
`;
