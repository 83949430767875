import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 16px);
  min-height: 500px;
  min-width: 350px;
  padding: 0 20px;
  @media (max-width: 600px) {
    min-height: 750px;
  }
`;

const font = `
  font-family: "Okomito-Regular", sans-serif;
  font-size: 30px;
  line-height: 1.27;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.5px;
  color: var(--color-black);
`;

export const Title = styled.h1`
  ${font}
  font-size: 60px;
`;

export const Text = styled.p`
  ${font}
  font-size: 30px;
  max-width: 800px;
  margin: 40px 0 100px;
`;

export const Button = styled.button`
  width: 100%;
  max-width: 400px;
  font-family: 'Okomito-Medium', sans-serif;
  font-size: 35px;
  color: var(--color-black);
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
  text-decoration: underline;

  @media (hover) {
    &:hover {
      color: var(--color-primary);
    }
  }
`;
