import { Children } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';

import { fontStyles } from 'theme';

export const Container = styled.header`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  width: 100%;
  position: sticky;
  top: 0;
  height: calc(var(--header-height) + 50px + 30px);
  z-index: 100;
  background-color: var(--color-white);
`;

export const Navbar = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: var(--header-height);
`;

export const LogoContainer = styled.div`
  width: 137px;
  display: flex;
  padding: 0 15px;
  justify-content: center;
  flex-direction: column;
`;

export const NameContainer = styled(NavLink)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  padding: 3px 0;
  text-decoration: none;
`;

export const P = styled.p`
  ${fontStyles.small}
  width: 100%;
  margin: 0;
  text-align: right;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const List = styled(motion.div).attrs(({ $isOpen, children }) => ({
  animate: $isOpen ? 'open' : 'close',
  variants: {
    open: {
      height: `calc(100% - var(--header-height))`,
      bottom: 0,
      overflow: 'auto',
      transition: {
        default: { duration: 0.5 },
        overflow: { delay: 0.5 + 0.1 * Children.count(children), duration: 0 },
        staggerChildren: 0.1,
      },
    },
    close: {
      height: 0,
      bottom: 'auto',
      transition: { duration: 0.05 },
      overflow: 'hidden',
    },
  },
  initial: 'close',
}))`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: var(--header-height);
  background: var(--color-white);
  z-index: 101;
  width: 100%;
`;
