import React from 'react';
import PropTypes from 'prop-types';

import { Container, Btn, Up, Down } from './styles';

const Button = ({ isOpen, ...rest }) => (
  <Btn type="button" {...rest}>
    <Container>
      <Up $isOpen={isOpen} />
      <Down $isOpen={isOpen} />
    </Container>
  </Btn>
);

Button.defaultProps = { isOpen: false };
Button.propTypes = { isOpen: PropTypes.bool };

export default Button;
