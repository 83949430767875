import { propOr, path, pipe, allPass, propEq, not } from 'ramda';

import { FEATURE_NAME } from './consts';

export const getFilters = propOr({}, FEATURE_NAME);
export const getFilter = (name) => path([FEATURE_NAME, name]);
export const getHasSearch = pipe(path([FEATURE_NAME, 'search']), Boolean);
export const getTouchedFilters = pipe(
  getFilters,
  allPass([propEq('sortParam', 'date'), propEq('sort', -1), propEq('category', ''), propEq('keyword', '')]),
  not
);
