import React from 'react';
import { useTranslation } from 'react-i18next';

import { ALLOW_CHANGE_LANGUAGE } from 'locale/consts';
import LangSwitcher from '../LangSwitcher';
import Sponsors from '../Sponsors';

import { Footer, Container, DropDown, Content, Link, A, CopyRight, Wrapper } from './styles';

const FooterMobile = () => {
  const { t } = useTranslation('app');

  return (
    <Footer>
      <Container>
        <DropDown title="Fintool" footer>
          <Content>
            <Link to="/geschenkkarte">{t('Gift card')}</Link>
            <Link to="/team">{t('Team')}</Link>
            <Link to="/medien">{t('Media')}</Link>
            <Link to="/partner">{t('Partner')}</Link>
            <Link to="/business">Geschäftskunden</Link>
          </Content>
        </DropDown>
        <DropDown title={t('Social media')} footer>
          <Content>
            <A href="https://www.facebook.com/fintool/" target="_blank">
              Facebook
            </A>
            <A href="https://www.linkedin.com/company/fintool/" target="_blank">
              LinkedIn
            </A>
            <A href="https://twitter.com/fintoolinfo" target="_blank">
              Twitter
            </A>
            <A href="https://www.instagram.com/fintool.ch/" target="_blank">
              Instagram
            </A>
          </Content>
        </DropDown>
        <DropDown title={t('Legal information')} footer>
          <Content>
            <Link to="/agb">{t('Terms and Conditions')}</Link>
            <Link to="/datenschutz">{t('Data protection')}</Link>
            <Link to="/impressum">{t('Imprint')}</Link>
            <CopyRight>{`© ${new Date().getFullYear()} Fintool`}</CopyRight>
          </Content>
        </DropDown>
        {ALLOW_CHANGE_LANGUAGE && (
          <Wrapper>
            <LangSwitcher />
          </Wrapper>
        )}
      </Container>
      <Sponsors />
    </Footer>
  );
};

export default FooterMobile;
