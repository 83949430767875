import { useCallback, useMemo, useEffect, useRef } from 'react';
import { useMutation as useDefMutation, useQuery as useDefQuery, useLazyQuery as useDefLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { pipe, map, path, filter } from 'ramda';

import { useDispatch, useAppContext } from 'hooks';
import { logout } from 'containers/authorization/actions';
import { getUserId, getUserEmail, getUserFirstName, getUserLastName } from 'containers/authorization/selectors';

import { NETWORK_ERROR, SESSION_ERROR, INTERNAL_SERVER_ERROR, DEFAULT_ERROR_MESSAGE_SHORT } from './constants';

export { uploadFile, loadBook } from './rest';

const useOnErrorHandler = () => {
  const [, setAppError] = useAppContext();
  const doLogout = useDispatch(logout);

  return useCallback(
    ({ networkError }) => {
      if (!networkError) return;

      const errors = path(['result', 'errors'], networkError);

      if (!errors) {
        setAppError(NETWORK_ERROR);

        return;
      }

      const errorTypes = pipe(map(path(['extensions', 'code'])), filter(Boolean))(errors);

      if (errorTypes.includes('SESSION_ERROR')) {
        setAppError(SESSION_ERROR);
        doLogout();

        return;
      }

      if (errorTypes.includes('INTERNAL_SERVER_ERROR')) {
        setAppError(INTERNAL_SERVER_ERROR);

        return;
      }

      setAppError(DEFAULT_ERROR_MESSAGE_SHORT);
    },
    [setAppError, doLogout]
  );
};

const extractError = (error) => {
  if (!error) return error;

  const { graphQLErrors } = error;
  const firstError = graphQLErrors && path([0, 'message'], graphQLErrors);

  return firstError || DEFAULT_ERROR_MESSAGE_SHORT;
};

export const useQuery = (graphql, options) => {
  const { t, i18n } = useTranslation('errors');
  const onError = useOnErrorHandler();
  const { error, refetch, fetchMore, ...rest } = useDefQuery(graphql, { onError, ...options });
  const errorMessage = useMemo(() => error && t([extractError(error), DEFAULT_ERROR_MESSAGE_SHORT]), [t, error]);
  const handleFetchMore = useCallback(
    async (...args) => {
      try {
        return await fetchMore(...args);
      } catch (e) {
        onError(e);
      }

      return null;
    },
    [fetchMore, onError]
  );
  const handleRefetch = useCallback(
    async (...args) => {
      try {
        return await refetch(...args);
      } catch (e) {
        onError(e);
      }

      return null;
    },
    [refetch, onError]
  );

  const language = useRef(i18n.language);
  const variables = options?.variables;

  useEffect(() => {
    if (language.current !== i18n.language) {
      handleRefetch(variables);
      language.current = i18n.language;
    }
  }, [i18n.language, handleRefetch, variables]);

  return { error: errorMessage, refetch: handleRefetch, fetchMore: handleFetchMore, ...rest };
};

export const useLazyQuery = (graphql, options) => {
  const { t } = useTranslation('errors');
  const onError = useOnErrorHandler();
  const [action, { error, ...rest }] = useDefLazyQuery(graphql, { onError, ...options });
  const errorMessage = useMemo(() => error && t([extractError(error), DEFAULT_ERROR_MESSAGE_SHORT]), [t, error]);

  return [action, { error: errorMessage, ...rest }];
};

export const useMutation = (graphql, options) => {
  const { t } = useTranslation('errors');
  const onError = useOnErrorHandler();
  const [action, { error, ...rest }] = useDefMutation(graphql, { onError, ...options });
  const errorMessage = useMemo(() => error && t([extractError(error), DEFAULT_ERROR_MESSAGE_SHORT]), [t, error]);

  return [action, { error: errorMessage, ...rest }];
};

export const useTwintLink = ({ id = null, price = 0, type }) => {
  const postfix = (type === 'video' && 'jrvtf') || (type === 'credit' && 'shqfq') || null;
  const userId = useSelector(getUserId);
  const email = useSelector(getUserEmail);
  const firstName = useSelector(getUserFirstName);
  const lastName = useSelector(getUserLastName);

  if (price > 0 && postfix && userId && email) {
    return `https://pay.raisenow.io/${postfix}?amount.values=${price / 100}${
      (id && `&reference.campaign_subid=${id}`) || ''
    }&reference.creditor=${userId}&supporter.email.value=${email}&supporter.first_name.value=${firstName}&supporter.last_name.value=${lastName}`;
  }

  return '';
};
