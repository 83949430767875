import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Container, Link, Button } from './styles';

const Item = ({ name, onClick, to, matches, isHightLight, isExternal }) => {
  const { pathname } = useLocation();
  const isActive = useMemo(
    () => matches?.find((path) => (path === '/' ? path === pathname : new RegExp(path).test(pathname))),
    [matches, pathname]
  );

  return (
    <Container $isActive={isActive} $isHightLight={isHightLight}>
      {to ? (
        <Link to={to} onClick={onClick} data-path={to} $isActive={isActive} $isHightLight={isHightLight} $isExternal={isExternal}>
          {name}
        </Link>
      ) : (
        <Button type="button" onClick={onClick}>
          {name}
        </Button>
      )}
    </Container>
  );
};

Item.defaultProps = {
  matches: null,
  onClick: () => null,
  to: null,
  isHightLight: false,
  isExternal: false,
};

Item.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  to: PropTypes.string,
  matches: PropTypes.arrayOf(PropTypes.string),
  isHightLight: PropTypes.bool,
  isExternal: PropTypes.bool,
};

export default Item;
