import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { fontStyles } from 'theme';

const Footer = styled.footer`
  width: 100%;
  border-top: 1px solid var(--color-primary);
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 25px 60px 25px 30px;
`;

const InsideContainer = styled.div`
  display: flex;
`;

const FooterMenu = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 120px;
  margin-right: ${({ alignRight }) => (alignRight ? '0' : '115px')};
`;

const A = styled(Link)`
  ${fontStyles.regularSmall}
  display: table;
  text-decoration: none;
  line-height: 21px;
  &:hover {
    color: var(--color-primary);
  }
  &:active {
    color: var(--color-primary);
  }
`;

const ExternalLink = styled.a`
  ${fontStyles.regularSmall}
  display: table;
  text-decoration: none;
  line-height: 21px;
  &:hover {
    color: var(--color-primary);
  }
  &:active {
    color: var(--color-primary);
  }
`;

const Title = styled.p`
  ${fontStyles.regularSmall}
  color: var(--color-primary);
  line-height: 21px;
`;

const CopyRight = styled.p`
  ${fontStyles.regularSmall}
  color: rgba(197, 30, 29, 0.42);
  line-height: 21px;
  margin-top: 21px;
`;

export { Footer, Container, FooterMenu, InsideContainer, Title, A, ExternalLink, CopyRight };
