import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import { fontStyles } from 'theme';

export const Container = styled(motion.div).attrs(({ $isOpen }) => ({
  animate: { height: $isOpen ? 'auto' : 0, opacity: $isOpen ? 1 : 0 },
  transition: { duration: 0.3 },
  initial: false,
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: var(--header-height);
  width: 100%;
  background: var(--color-primary);
  overflow: hidden;
  z-index: 99;
  & svg {
    transition: all 0.3s;
  }
  & svg:hover {
    transform: scale(1.2);
  }
  @media (max-width: 1023px) {
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
  }
`;

export const Text = styled.p`
  ${fontStyles.regularSmall}
  color: var(--color-white);
  margin: 6px 8px 6px 0;
  -webkit-text-size-adjust: 100%;
`;

export const Highlight = styled(Link)`
  ${fontStyles.regularSmall}
  font-family: "Okomito-Medium", sans-serif;
  color: var(--color-white);
  transition: all 0.3s;

  &:hover {
    color: var(--color-grey-f4);
  }
`;

export const Button = styled.button`
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  padding-top: 3px;
  @media (max-width: 1023px) {
    margin-left: 80px;
    & > svg {
      width: 32px;
      height: 32px;
    }
  }
`;
