export const PATH_TO_MAIN_PAGE = '/videos';
export const FEATURE_NAME = 'auth';
export const NAME = 'firstname';
export const FIRST_NAME = 'firstname';
export const LAST_NAME = 'lastname';
export const EMAIL = 'email';
export const PASSWORD = 'password';
export const NEW_PASSWORD = 'newPassword';
export const ACCOUNT = 'account';
export const PREMIUM = 'premium';
export const FREE = 'free';
export const TOKEN = 'token';
export const GENDER = 'gender';
export const ADDRESS = 'address';
export const ZIP_CODE = 'zipCode';
export const CITY = 'city';
export const COUNTRY = 'country';
export const WORKPLACE = 'workplace';
export const TERMS_ACCEPTED = 'termsAccepted';
export const VOUCHER = 'voucher';
export const PRIVACY = 'privacy';
export const NOTIFICATIONS = 'notifications';
export const NEWSLETTER = 'newsletter';
export const PUBLIC_PROFILE = 'publicProfile';
export const PRODUCT_ID = 'productId';
