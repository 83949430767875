import { API_URL_REST, API_TOKEN_REST, API_URL } from './constants';
import { localStorage } from './storages';

const wrapperRequest = async ({ url, options }) => {
  const response = await fetch(url, options);

  if (response.status >= 200 && response.status < 300) {
    return response.json();
  }

  const message = response.status === 401 ? 'Authentication failed' : response.status;
  const error = new Error(message);
  error.response = response;
  error.status = response.status;
  throw error;
};

export const uploadFile = (file) => {
  const data = new FormData();
  data.append('file', file);

  return wrapperRequest({
    url: `${API_URL_REST}/uploads`,
    options: {
      headers: {
        AUTHORIZATION: `api-key ${API_TOKEN_REST}`,
      },
      method: 'POST',
      body: data,
    },
  });
};

export const loadBook = async (urlParam) => {
  const { url } = await wrapperRequest({
    url: `${API_URL}/books/${urlParam}`,
    options: {
      headers: {
        'x-token': localStorage.getItem('token') || '',
      },
    },
  });
  localStorage.setItem('smartbooks', { [urlParam]: url });

  const ownUrl = `${process.env.REACT_APP_URL}/smartbooks/${urlParam}`;
  const popup = window.open(ownUrl);

  return !popup || popup.closed || typeof popup.closed === 'undefined' ? ownUrl : null;
};
