import React from 'react';
import { useTranslation } from 'react-i18next';

import { ALLOW_CHANGE_LANGUAGE } from 'locale/consts';
import LangSwitcher from '../LangSwitcher';
import Sponsors from '../Sponsors';

import { Footer, Container, FooterMenu, InsideContainer, Title, A, ExternalLink, CopyRight } from './styles';

const currentYear = new Date().getFullYear();
const copyright = `© ${currentYear} Fintool`;

const FooterDefault = () => {
  const { t } = useTranslation('app');

  return (
    <Footer>
      <Container>
        <InsideContainer>
          <FooterMenu>
            <Title>Fintool</Title>
            <A to="/geschenkkarte">{t('Gift card')}</A>
            <A to="/team">{t('Team')}</A>
            <A to="/medien">{t('Media')}</A>
            <A to="/partner">{t('Partner')}</A>
            <A to="/business">Geschäftskunden</A>
          </FooterMenu>
          <FooterMenu>
            <Title>{t('Social media')}</Title>
            <ExternalLink href="https://www.facebook.com/fintool/" target="_blank">
              Facebook
            </ExternalLink>
            <ExternalLink href="https://www.linkedin.com/company/fintool/" target="_blank">
              LinkedIn
            </ExternalLink>
            <ExternalLink href="https://twitter.com/fintoolinfo" target="_blank">
              Twitter
            </ExternalLink>
            <ExternalLink href="https://www.instagram.com/fintool.ch/" target="_blank">
              Instagram
            </ExternalLink>
          </FooterMenu>
          <FooterMenu>
            <Title>{t('Legal information')}</Title>
            <A to="/agb">{t('Terms and Conditions')}</A>
            <A to="/datenschutz">{t('Data protection')}</A>
            <A to="/impressum">{t('Imprint')}</A>
            <CopyRight>{copyright}</CopyRight>
          </FooterMenu>
        </InsideContainer>
        {ALLOW_CHANGE_LANGUAGE && (
          <FooterMenu alignRight>
            <LangSwitcher />
          </FooterMenu>
        )}
      </Container>
      <Sponsors />
    </Footer>
  );
};

export default FooterDefault;
