import { handleActions } from 'redux-actions';
import Bugsnag from '@bugsnag/js';

import { localStorage } from 'api/storages';

import { AUTH, LOGOUT, UPDATE_USER_DATA } from './types';

const initialState = {
  auth: false,
  user: {
    card: {},
  },
};

const isProduction = process.env.REACT_APP_ENV === 'production';
const isStaging = process.env.REACT_APP_ENV === 'staging';

export default handleActions(
  {
    [AUTH]: (state, { payload: { token, user } }) => {
      localStorage.setItem('token', token);

      if ((isProduction || isStaging) && user?.id) Bugsnag.setUser(user.id);

      return {
        ...state,
        auth: Boolean(localStorage.getItem('token')),
        firstTime: false,
        ...(user && { user: { ...state.user, ...user } }),
      };
    },
    [LOGOUT]: () => {
      localStorage.removeItem('token');
      return initialState;
    },
    [UPDATE_USER_DATA]: (state, { payload }) => {
      if ((isProduction || isStaging) && payload?.id) Bugsnag.setUser(payload.id);

      return {
        ...state,
        auth: true,
        firstTime: false,
        user: { ...state.user, ...payload },
      };
    },
  },
  initialState
);
