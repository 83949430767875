import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { fontStyles } from 'theme';

export const Container = styled.header`
  display: grid;
  grid-template-columns: auto auto 1fr;
  align-items: center;
  column-gap: 15px;
  position: sticky;
  top: 0;
  height: var(--header-height);
  z-index: 100;
  width: 100%;
  height: 50px;
  padding: 0 8px;
  background: var(--color-white);
`;

export const Link = styled(NavLink)`
  padding: 0 20px 10px;
  @media (max-width: 1100px) {
    padding: 0 0 10px 10px;
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ $withSearchInput, $amount }) =>
    $withSearchInput ? `1fr repeat(${$amount}, auto)` : `repeat(${$amount}, auto)`};
  grid-template-rows: auto;
  column-gap: 15px;
  justify-content: end;
  align-items: center;
`;

export const DropdownItem = styled(NavLink).attrs(({ $isExternal }) =>
  $isExternal
    ? {
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : {}
)`
  display: block;
  height: 33px;
  width: 100%;
  border-top: 1px solid var(--color-primary);
  background-color: ${({ disabled, $isHightLight }) =>
    $isHightLight && !disabled ? 'var(--color-primary)' : 'var(--color-white)'};
  padding: 0 12px;
  ${fontStyles.regularSmall}
  line-height: 30px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: none;
  color: ${({ disabled, $isHightLight }) =>
    (disabled && 'var(--color-primary)') || ($isHightLight && 'var(--color-white)') || 'var(--color-black)'};
  &:first-child {
    border: none;
  }
  @media (hover) {
    &:hover {
      background-color: var(--color-white);
      color: var(--color-primary);
    }
  }
`;
