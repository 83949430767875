import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useMobileDetect } from 'hooks';
import { updateFilters } from 'containers/posts/actions';

import MobileHeader from './MobileHeader';
import DesktopHeader from './DesktopHeader';

const Header = () => {
  const isMobile = useMobileDetect();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const resetSearch = useCallback(() => {
    if (pathname === '/videos') dispatch(updateFilters({ search: '' }));
  }, [dispatch, pathname]);

  return isMobile ? <MobileHeader resetSearch={resetSearch} /> : <DesktopHeader resetSearch={resetSearch} />;
};

export default Header;
