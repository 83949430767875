import React from 'react';

import { Container, Ring } from './styles';

const Spinner = (props) => (
  <Container {...props}>
    <Ring {...props}>
      <div />
      <div />
      <div />
      <div />
    </Ring>
  </Container>
);

export default Spinner;
