import React from 'react';
import { useLocation } from 'react-router-dom';

import { useMobileDetect } from 'hooks';

import FooterDefault from './FooterDefault';
import FooterMobile from './FooterMobile';

const Footer = () => {
  const { pathname } = useLocation();
  const mobile = useMobileDetect();

  if (pathname === '/intro') return null;

  if (mobile) return <FooterMobile />;

  if (pathname.includes('/akademie/kurse/')) return null;

  return <FooterDefault />;
};

export default Footer;
