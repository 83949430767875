import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { LANG_LABELS } from 'locale/consts';

import { Container, Dropdown } from './styles';

const LangSwitcher = () => {
  const { i18n } = useTranslation();
  const handleSelect = useCallback(({ target }) => i18n.changeLanguage(target.value), [i18n]);

  return (
    <Container>
      <Dropdown value={i18n.language} values={LANG_LABELS} onChange={handleSelect} aria-label="Language" />
    </Container>
  );
};

export default LangSwitcher;
