import styled from 'styled-components';
import { motion } from 'framer-motion';

import { fontStyles } from 'theme';
import ArrowIcon from 'components/ArrowIcon';

export const Container = styled.div`
  position: relative;
  z-index: 100;
`;

export const Button = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${({ width }) => width}px;
  height: 33px;
  background-color: var(--color-white);
  border: 1px solid var(--color-black);
  border-radius: ${({ isOpen }) => (isOpen ? 'var(--border-radius) var(--border-radius) 0 0' : 'var(--border-radius)')};
  padding: 0 12px;
  cursor: pointer;
  @media (hover) {
    ${({ isActive, isOpen }) =>
      !(isActive || isOpen) &&
      `
      &:hover {
        border-color: var(--color-primary);
        color: var(--color-primary);
      }
    `}
  }
  ${({ isActive, isOpen }) =>
    (isActive || isOpen) &&
    `
    border-color: var(--color-primary);
    color: var(--color-primary);
  `}
`;

export const Text = styled.p`
  flex: 1 1 auto;
  ${fontStyles.regularSmall}
  color: inherit;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 12px;
`;

export const Icon = styled(ArrowIcon).attrs(({ isActive, isOpen }) => ({
  color: isActive || isOpen ? 'var(--color-primary)' : 'var(--color-black)',
}))`
  height: 18px;
  transform: scale(0.75);
  @media (hover) {
    ${Button}:hover & > div {
      background-color: var(--color-primary);
    }
  }
`;

export const Content = styled(motion.div).attrs(({ $isOpen }) => ({
  animate: { height: $isOpen ? 'auto' : 0, borderWidth: $isOpen ? '0 1px 1px 1px' : '0 0 0 0', opacity: $isOpen ? 1 : 0 },
  transition: {
    borderWidth: { delay: 0, duration: 0 },
    default: { duration: 0.2 },
  },
  initial: { height: 0, borderWidth: '0 0 0 0', opacity: 0 },
}))`
  position: absolute;
  top: 33px;
  right: 0;
  left: 0;
  overflow: hidden;
  border: solid var(--color-primary);
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  background-color: var(--color-white);
  z-index: 100;
`;
