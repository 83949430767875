import styled, { keyframes } from 'styled-components';

const ring = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const Ring = styled.div`
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  width: ${({ size }) => size || 50}px;
  height: ${({ size }) => size || 50}px;
  border: 1px solid var(--color-primary);
  border-radius: 50%;
  margin: calc(${({ size }) => (size ? `${Math.abs(size - 100)}px` : '50vh - 50px')});
  & div {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: 4px solid var(--color-primary);
    border-radius: 50%;
    animation: ${ring} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--color-primary) transparent transparent transparent;
  }
  & div:nth-child(1) {
    animation-delay: -0.45s;
  }
  & div:nth-child(2) {
    animation-delay: -0.3s;
  }
  & div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;
