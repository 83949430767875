/* eslint-disable no-underscore-dangle */
import { createStore } from 'redux';
import combinedReducers from './reducers';

const store = createStore(
  combinedReducers,
  process.env.REACT_APP_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
