import Bowser from 'bowser';
import { repeat, prop, ifElse, hasPath, path, pathOr } from 'ramda';

import i18n from 'locale';
import { FR, IT } from 'locale/consts';

const getLocale = () => {
  const mapping = {
    [FR]: 'fr-CH',
    [IT]: 'it-CH',
  };

  return mapping[i18n.language] || i18n.language;
};

const getDateFormatter = () =>
  new Intl.DateTimeFormat(getLocale(), {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });

const getTimeFormatter = () =>
  new Intl.DateTimeFormat(getLocale(), {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  });

export const formatDate = (timestamp, withoutTime) => {
  if (!timestamp) return '';

  const date = new Date(timestamp);

  return withoutTime ? getDateFormatter().format(date) : ` ${getDateFormatter().format(date)} ${getTimeFormatter().format(date)}`;
};

export const formatSplitByThousand = (num) => {
  if (!num || typeof num !== 'number') return null;

  const result = [];

  String(num)
    .split('')
    .reverse()
    .forEach((item, i, arr) => {
      if ((i + 1) % 3 || i + 1 === arr.length) {
        result.push(item);
      } else {
        result.push(item);
        result.push("'");
      }
    });

  return result.reverse().join('');
};

export const handleCurrency = (currency) => (currency ? currency.toUpperCase() : '');

export const handlePrice = (price) => {
  if (price === 0) return '0.–';
  if (!price) return '';

  return price % 100 ? Number(price / 100).toFixed(2) : `${price / 100}.–`;
};

export const revertToMinute = (time) => Math.ceil(time / 60) || 0;

export const handleGqlErrorMessage = (e) => {
  try {
    return pathOr(e?.message?.replace('GraphQL error: ', ''), ['graphQLErrors', 0, 'message'], e);
  } catch (error) {
    return e;
  }
};

export const isDisableMixBlendMode = Bowser.getParser(window.navigator.userAgent).satisfies({ edge: '>0' });

export const extractYupErrors = ({ inner = [] }) =>
  inner.reduce((acc, { path: errField, message }) => {
    acc[errField] = message;

    return acc;
  }, {});

const errPath = ['graphQLErrors', 0, 'message'];
export const extractGqlErrors = (error) => error && ifElse(hasPath(errPath), path(errPath), prop('message'))(error);

export const getCardNumber = (last4, t) =>
  last4 ? [...repeat('\u2022\u2022\u2022\u2022', 3), last4].join(' ') : t('Not available');

export const getRandom = (min, max) => Math.round(min - 0.5 + Math.random() * (max - min + 1));

export const shuffleList = (list) => {
  if (!list?.length) return list;

  const temp = [...list];
  const result = [];

  for (; temp.length; ) {
    const randomIndex = getRandom(0, temp.length - 1);

    result.push(temp[randomIndex]);
    temp.splice(randomIndex, 1);
  }

  return result;
};
