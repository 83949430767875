import { path, pipe, lte, when, allPass, test } from 'ramda';

import { FEATURE_NAME as $ } from './consts';
import { dateWithoutTime } from './utils';

export const isAuth = path([$, 'auth']);
export const getUser = path([$, 'user']);
export const getUserId = path([$, 'user', 'id']);
export const getUserEmail = path([$, 'user', 'email']);
export const getUserStatus = path([$, 'user', 'status']);
export const getUserFirstName = path([$, 'user', 'firstname']);
export const getUserLastName = path([$, 'user', 'lastname']);
export const getIsSubscribed = pipe(
  path(['auth', 'user', 'subscription', 'duration']),
  when(Boolean, pipe(dateWithoutTime, lte(dateWithoutTime(Date.now()))))
);
export const getRenew = path([$, 'user', 'subscription', 'renew']);
export const getSubscribeId = path([$, 'user', 'subscription', 'id']);
export const getSubscribeDate = path([$, 'user', 'subscription', 'duration']);
export const getSubscribePeriod = path([$, 'user', 'subscription', 'period']);
export const getLast4Card = path([$, 'user', 'card', 'last4']);
export const getPrivacy = path([$, 'user', 'privacy']);

export const getUserName = (state) => {
  const [first, last] = [getUserFirstName(state), getUserLastName(state)];

  if (!first && !last) return getUserEmail(state);

  return `${first} ${last}`;
};

export const getIsFintoolEmail = allPass([isAuth, pipe(getUserEmail, test(/.+@fintool.ch$/i))]);
