import React, { useState, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useAppContext } from 'hooks';
import { isAuth, getIsSubscribed, getUserId } from 'containers/authorization/selectors';
import Close from 'assets/svg/Close';

import { useCheck } from './hooks';
import { Container, Text, Highlight, Button } from './styles';

const SubscribeMessage = () => {
  const ref = useRef();
  const refOffset = useRef();
  const { t } = useTranslation(['app', 'errors']);
  const { pathname } = useLocation();
  const [isShow, setIsShow] = useState(false);
  const auth = useSelector(isAuth);
  const userId = useSelector(getUserId);
  const isSubscribed = useSelector(getIsSubscribed);
  const isIntro = useMemo(() => pathname === '/intro', [pathname]);

  const handleClose = useCallback(() => setIsShow(false), [setIsShow]);
  const pathnameToPremium = useMemo(() => (auth ? '/account/abo' : '/registrieren'), [auth]);
  const [errorApp] = useAppContext();

  useCheck(isSubscribed, isIntro, Boolean(userId), setIsShow);

  const content = useMemo(() => {
    if (errorApp) {
      return (
        <Container ref={ref} $isOpen>
          <Text>
            {t(`errors:${errorApp}`)} <Highlight to="/support">{`${t('Report a problem')}.`}</Highlight>
          </Text>
        </Container>
      );
    }
    return (
      <Container ref={ref} $isOpen={isShow}>
        {!isIntro && (
          <>
            <Text>
              {t('Now get yourself')} <Highlight to={pathnameToPremium}>Fintool Premium</Highlight>{' '}
              {t('and get access to our academy')}
            </Text>
            <Button type="button" onClick={handleClose} title="Close" aria-label="Close">
              <Close />
            </Button>
          </>
        )}
      </Container>
    );
  }, [errorApp, isShow, isIntro, pathnameToPremium, t, handleClose]);

  return (
    <>
      <div ref={refOffset} />
      {content}
    </>
  );
};

export default SubscribeMessage;
