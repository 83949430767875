import { handleActions } from 'redux-actions';

import { UPDATE_FILTERS, RESET_FILTERS } from './types';

const initialState = {
  search: '',
  sortParam: 'date',
  sort: -1,
  category: '',
  keyword: '',
};

export default handleActions(
  {
    [UPDATE_FILTERS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [RESET_FILTERS]: () => initialState,
  },
  initialState
);
