import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { fontStyles } from 'theme';

export const Wrapper = styled.div`
  height: 33px;
  border: 1px solid;
  border-color: ${({ $isHightLight, $isActive }) => ($isHightLight || $isActive ? 'var(--color-primary)' : 'var(--color-black)')};
  border-radius: var(--border-radius);
  background-color: ${({ $isHightLight, $isActive }) =>
    $isHightLight && !$isActive ? 'var(--color-primary)' : 'var(--color-white)'};
  padding: 0 12px;
  width: fit-content;
  max-width: 150px;
  min-width: 100px;
  @media (hover) {
    &:hover {
      border-color: var(--color-primary);
      background-color: var(--color-white);
    }
  }
`;

export const LinkContainer = styled(NavLink).attrs(({ $isExternal }) =>
  $isExternal
    ? {
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : {}
)`
  display: block;
  height: 100%;
  width: 100%;
  ${fontStyles.regularSmall}
  color: ${({ $isHightLight, $isActive }) => {
    if ($isActive) return 'var(--color-primary)';
    if ($isHightLight) return 'var(--color-white)';

    return 'var(--color-black)';
  }};
  line-height: 30px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: none;
  @media (hover) {
    ${Wrapper}:hover > & {
      color: var(--color-primary);
    }
  }
`;
