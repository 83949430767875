import styled from 'styled-components';
import { motion } from 'framer-motion';

const style = `
  position: absolute;
  top: 0;
  width: 32px;
  height: 1px;
  background: var(--color-primary);
`;

export const Container = styled.div`
  position: relative;
  left: 15px;
  top: 4px;
  width: 32px;
  height: 32px;
`;

export const Btn = styled.button`
  background: none;
  outline: none;
  border: none;
  width: 62px;
  overflow: hidden;
`;

export const Up = styled(motion.div).attrs(({ $isOpen }) => ({
  animate: { top: $isOpen ? 11 : 18, rotate: $isOpen ? -45 : 0 },
  transition: {
    top: { duration: $isOpen ? 0.1 : 0.25, delay: $isOpen ? 0 : 0.25, ease: 'easeOut' },
    rotate: { duration: $isOpen ? 0.4 : 0.25, delay: $isOpen ? 0.1 : 0, ease: 'easeOut' },
  },
  initial: false,
}))`
  ${style}
`;

export const Down = styled(motion.div).attrs(({ $isOpen }) => ({
  animate: { top: $isOpen ? 11 : 4, rotate: $isOpen ? 45 : 0 },
  transition: {
    top: { duration: $isOpen ? 0.1 : 0.25, delay: $isOpen ? 0 : 0.25, ease: 'easeOut' },
    rotate: { duration: $isOpen ? 0.4 : 0.25, delay: $isOpen ? 0.1 : 0, ease: 'easeOut' },
  },
  initial: false,
}))`
  ${style}
`;
