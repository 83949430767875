import React, { useMemo } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useAppContext } from 'hooks';
import { getUserStatus } from 'containers/authorization/selectors';

import { AppContext } from './contexts';
import { ROUTES, IndexPage } from './consts';
import Layout from './Layout';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

const Root = () => {
  const context = useAppContext(true);
  const status = useSelector(getUserStatus);
  const routes = useMemo(() => (status === 3 ? ROUTES.filter(({ path }) => path !== '/account') : ROUTES), [status]);

  return (
    <AppContext.Provider value={context}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<PublicRoute component={IndexPage} />} />
          {routes.map(({ path, isPrivate, component, subItems }) => (
            <Route
              key={path}
              path={path}
              element={isPrivate ? <PrivateRoute component={component} /> : <PublicRoute component={component} />}
            >
              {Boolean(subItems?.length) &&
                subItems.map(({ path: subPath, component: Component, props }) => (
                  <Route key={subPath || 'index'} index={!subPath} path={subPath} element={<Component {...props} />} />
                ))}
            </Route>
          ))}
          <Route path="*" element={<Navigate to="/videos" />} />
        </Route>
      </Routes>
    </AppContext.Provider>
  );
};

export default Root;
