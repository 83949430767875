import { gql } from '@apollo/client';

export const GET_COURSES_AND_PROGRAMS = gql`
  query coursesAndPrograms {
    courses {
      id
      name
      description
    }
    programs {
      id
      name
      description
    }
  }
`;

export const GET_COURSE_PROGRESSES = gql`
  query courseProgressesForUser {
    courseProgressesForUser {
      id
      course {
        id
      }
    }
  }
`;

export const GET_POSTS = gql`
  query posts($limit: Int) {
    posts(limit: $limit) {
      edges {
        id
        title
        content
      }
      pageInfo {
        total
      }
    }
  }
`;
