export const getMobileMenu = (isSignedIn, status) => [
  {
    key: 'akademie',
    name: 'Academy',
    link: '/akademie',
    matches: ['/akademie', 'themen', '/kurse', '/lectures', '/assessments'],
  },
  {
    key: 'videos',
    name: 'Video studio',
    link: '/videos',
    matches: ['/', '/videos', '/videos/'],
  },
  {
    key: 'books',
    name: 'Bookshop',
    link: '/books',
    matches: ['/books', '/books/'],
  },
  // {
  //   key: 'tickets',
  //   name: 'Tickets',
  //   link: 'https://www.eventim-light.com/ch/a/5bedde6727fab80001be552a/e/6622352086e1a86afa999105',
  //   isExternal: true,
  //   isHightLight: true,
  // },
  {
    key: 'support',
    name: 'Help Center',
    link: '/support',
    matches: ['/support'],
  },
  ...((isSignedIn &&
    status === 3 && [
      {
        key: 'meine-kurse',
        name: 'My courses',
        link: '/meine-kurse',
        matches: ['/meine-kurse'],
      },
      {
        key: 'logout',
        name: 'Logout',
      },
    ]) ||
    (isSignedIn && [
      {
        key: 'meine-kurse',
        name: 'My courses',
        link: '/meine-kurse',
        matches: ['/meine-kurse'],
      },
      {
        key: 'account',
        name: 'My profile',
        link: '/account',
        matches: ['/account'],
      },
      {
        key: 'logout',
        name: 'Logout',
      },
    ]) || [
      {
        key: 'videoletter',
        name: 'Videoletter',
        link: '/videoletter',
        matches: ['/videoletter'],
      },
      {
        key: 'anmelden',
        name: 'Login',
        link: '/anmelden',
        matches: ['/anmelden'],
      },
      {
        key: 'registrieren',
        name: 'Register',
        link: '/registrieren',
        matches: ['/registrieren'],
      },
    ]),
];
