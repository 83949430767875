import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { fontStyles } from 'theme';
import Btn from 'components/AsyncButton';

export const Container = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-pink);
  border: 1px solid var(--color-black);
  border-width: 1px 0;
  padding: 20px 30px;
  z-index: 10;
  @media (max-width: 1023px) {
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px 20px 40px 20px;
  }
`;

export const Text = styled.p`
  ${fontStyles.regularLarge}
`;

export const Link = styled(NavLink)`
  ${fontStyles.regularLarge}
  @media (hover) {
    &:hover {
      color: var(--color-primary);
    }
  }
  &:active {
    color: var(--color-primary);
  }
`;

export const ButtonWrap = styled.div`
  display: flex;
  margin-left: 20px;
  @media (max-width: 1023px) {
    width: 100%;
    max-width: 470px;
    flex-direction: column;
    margin-left: 0;
  }
`;

export const Button = styled(Btn)`
  width: 220px;
  margin: 0;
  &:first-child {
    margin-right: 20px;
  }
  @media (max-width: 1023px) {
    width: 100%;
    &:first-child {
      margin: 40px 0 20px 0;
    }
  }
`;
