import React from 'react';

export default (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd" stroke="var(--color-primary)" transform="translate(2 2)">
      <circle cx="7.5" cy="7.5" r="7.5" />
      <path fillRule="nonzero" d="M13 13l7.196 7.196" />
    </g>
  </svg>
);
