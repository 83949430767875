export const FEATURE_NAME = 'posts';

export const SORT_ITEMS = [
  {
    sortParam: 'date',
    sort: -1,
    name: 'Neuste zuerst',
  },
  {
    sortParam: 'date',
    sort: 1,
    name: 'Älteste zuerst',
  },
  {
    sortParam: 'rating',
    sort: -1,
    name: 'Beste Bewertung',
  },
];
