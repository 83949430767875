import React, { useRef, useMemo, useLayoutEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { takeLast, take, complement, propEq, filter } from 'ramda';
import PropTypes from 'prop-types';

import { isAuth, getUserName, getUserStatus } from 'containers/authorization/selectors';
import { useAsyncState } from 'hooks';
import Logo from 'assets/svg/FintoolBlack';
import SearchBar from '../SearchBar';

import { getDesktopHeader } from './utils';
import NavButton from './NavButton';
import LogoutButton from './LogoutButton';
import Dropdown from './Dropdown';
import { Container, Link, Wrapper, DropdownItem } from './styles';

const WIDTHS = [1250, 1275];
const DROPDOWN_AMOUNT = 3;

const DesktopHeader = ({ resetSearch }) => {
  const isSignedIn = useSelector(isAuth);
  const status = useSelector(getUserStatus);
  const [dropdownAmount, setDropdownAmount] = useAsyncState(
    (isSignedIn && window.innerWidth <= WIDTHS[1]) || (!isSignedIn && window.innerWidth <= WIDTHS[0]) ? DROPDOWN_AMOUNT : 0
  );
  const userName = useSelector(getUserName);
  const { pathname } = useLocation();
  const { t } = useTranslation('app');

  const desktopHeader = useMemo(() => getDesktopHeader(isSignedIn, status), [isSignedIn, status]);
  const rightItems = useMemo(() => {
    if (dropdownAmount === 0) return desktopHeader[1];

    return isSignedIn
      ? filter(propEq('key', 'account'), desktopHeader[1])
      : takeLast(desktopHeader[1].length - dropdownAmount, desktopHeader[1]);
  }, [desktopHeader, dropdownAmount, isSignedIn]);
  const dropdownItems = useMemo(() => {
    if (dropdownAmount === 0) return [];

    return isSignedIn ? filter(complement(propEq('key', 'account')), desktopHeader[1]) : take(dropdownAmount, desktopHeader[1]);
  }, [desktopHeader, dropdownAmount, isSignedIn]);

  const activeDropdown = useMemo(
    () => dropdownItems.reduce((acc, { matches }) => (matches ? [...acc, ...matches] : acc), []).includes(pathname),
    [dropdownItems, pathname]
  );

  const animationFrame = useRef(null);
  const handleResize = useCallback(() => {
    if (animationFrame.current) {
      window.cancelAnimationFrame(animationFrame.current);
    }

    animationFrame.current = window.requestAnimationFrame(() => {
      const { innerWidth } = window;

      if ((!isSignedIn && innerWidth <= WIDTHS[0]) || (isSignedIn && innerWidth <= WIDTHS[1])) setDropdownAmount(DROPDOWN_AMOUNT);
      if ((!isSignedIn && innerWidth > WIDTHS[0]) || (isSignedIn && innerWidth > WIDTHS[1])) setDropdownAmount(0);
    });
  }, [isSignedIn, setDropdownAmount]);

  useLayoutEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    const timer = animationFrame.current;

    return () => {
      window.removeEventListener('resize', handleResize);

      if (timer) {
        window.cancelAnimationFrame(timer);
      }
    };
  }, [handleResize]);

  return (
    <Container>
      <Link to="/" aria-label="Fintool">
        <Logo />
      </Link>
      <Wrapper $amount={desktopHeader[0].length}>
        {desktopHeader[0].map(({ key, name, link, matches, isExternal, isHightLight }) => (
          <NavButton
            key={key}
            name={t(name)}
            link={link}
            onClick={link === '/videos' ? resetSearch : undefined}
            matches={matches}
            isExternal={isExternal}
            isHightLight={isHightLight}
          />
        ))}
      </Wrapper>
      <Wrapper $amount={rightItems.length + Math.min(dropdownAmount, 1)} $withSearchInput>
        <SearchBar />
        {rightItems.map(({ key, name, link, matches, isExternal, isHightLight }) =>
          key === 'logout' ? (
            <LogoutButton key={key} name={t(name)} />
          ) : (
            <NavButton
              key={key}
              name={(key === 'account' && userName) || t(name)}
              link={link}
              matches={matches}
              isExternal={isExternal}
              isHightLight={isHightLight}
            />
          )
        )}
        {dropdownAmount > 0 && (
          <Dropdown name={t('More')} isActive={activeDropdown} width={150}>
            {dropdownItems.map(({ key, name, link, matches, isExternal, isHightLight }) =>
              key === 'logout' ? (
                <LogoutButton key={key} name={t(name)} isDropdown />
              ) : (
                <DropdownItem
                  key={key}
                  to={link}
                  disabled={matches?.includes(pathname)}
                  $isExternal={isExternal}
                  $isHightLight={isHightLight}
                >
                  {(key === 'account' && userName) || t(name)}
                </DropdownItem>
              )
            )}
          </Dropdown>
        )}
      </Wrapper>
    </Container>
  );
};

DesktopHeader.propTypes = { resetSearch: PropTypes.func.isRequired };

export default DesktopHeader;
